
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import BtnCheck from "@/components/scope/btn-check.vue";
import dayjs from "dayjs";
import { priceRulesEl, mobileRulesEl, isPositiveInteger, partsQuantityRules } from "@/utils/verify";
import t from "@common/src/i18n/t";
import FileUpload from "@/components/scope/file-upload.vue";
const management = namespace("management");
const parts = namespace("parts");
const user = namespace("user");
const app = namespace("app");
const base = namespace("base");
const supplier = namespace("supplier");
const serviceItem = namespace("service-item");
const marketing = namespace("marketing");


@Component({
  components: { BtnCheck, FileUpload }
})
export default class ServiceEdit extends Vue {
  @app.Mutation setPBPX;
  @supplier.Action getSupplierDetail;
  @parts.Action getPartsList;
  @parts.Action getPartsDetail;
  @serviceItem.Action getServiceList;
  @serviceItem.Action getServiceDetail;
  @marketing.Action queryCardTemplateDetail;
  @marketing.Action createCardTemplate;
  @marketing.Action updateCardTemplate;
  @user.State userData;
  @base.Action getDictionaryList;
  @app.State btnLoading;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;
  @management.Action getAllStoreList;
  @management.Action getStoreCouponList
  @management.Action getStoreList
  dayjs = dayjs;
  splitThousands = splitThousands;
  get breadData() {
    return [
      { name: t("marketing.card-template"), path: "/management/card-template" },
      {
        name: this.isAdd ? t("marketing.add-card-template") : t("marketing.edit-card-template")
      }
    ];
  }
  get isAdd() {
    return this.$route.name === "ManagementCardTemplateAdd";
  }
  handleShowCreateSupplier() {
    this.showCreateSupplier = true;
  }
  validateMobile(rule, value, callback) {
    if (!value) {
      callback();
    } else {
      mobileRulesEl(value, callback);
    }
  }
  emptyToLine = emptyToLine;
  groupIndex: number = 1;
  orderFormData: any = {
    cardTemplateName: "", // 模板名称
    cardAmount: "",
    validPeriod: "",
    isPermanent: false, // 是否永久有效
    enableStatus: 1,
    cardDesc: "", // 卡说明
    serviceList: [],
    partsList: [],
    cardType: "1", //1：套餐卡、2储蓄卡）
    giftAmount: null, // 赠送金额
    carryingAmount: 0, // 总计账面金额
    storeTypes: [],
    validTimeEnd: null,
    validTimeBegin: null,
    icon: "",
    validPeriodType: 1,
    isShow: 1,
    thirdMapList: [],
    applicableStores:[],

    useTimeType: 1,
    useTimeRange:undefined,
    endTimeIsNextDay: 0,
    useTimeBegin: '',
    useTimeEnd: '',
  };
  storeList: any=[]
  iconFileList: any = [];
  definitionServiceData: any = {
    isGroup: true,
    serviceCode: "",
    serviceCount: "",
    workingHours: "",
    serviceFee: "",
    carryingAmount: "",
    partsList: [] // 存放套餐项目的零配件
  };
  groupChannel: any = [
    {value: 2,label: "抖音"},
    {value: 3,label: "美团"},
    {value: 7,label: "快手"},
  ];
  definitionPartsData: any = {
    isGroupParts: false, // 是否套餐零配件
    servicePartsQty: 0,
    partsUnit: "", // 单位
    carryingAmount: "", // 账面金额
    amount: "",
    partsCount: "", // 数量
    sellingPrice: "", // 参考销售单价（元）
    partsName: "",
    partsCode: "" // 零配件code
  };

  showCouponPopup: boolean = false;
  definitionGroupData: any = {
    source: null,
    thirdTicketId: "",
    couponTemplateCode: "",
    couponTemplateName: "",
  };
  showCreateSupplier: boolean = false;
  newSupplierData: any = {
    supplierName: "",
    contactName: "",
    contactMobile: ""
  };
  partsList: any = [];
  serviceItemList: any = [];
  storeTypeList: any = [];
  orderFormDataRules: any = {
    cardTemplateName: { required: true, message: t("marketing.please-input-card-name"), trigger: "blur" },
    cardAmount: [
      { required: true, message: t("marketing.input-open-card-amount"), trigger: "blur" },
      {
        validator: this.checkPrice,
        trigger: "blur"
      }
    ],
    validPeriod: [
      { required: true, message: t("marketing.input-valid-period"), trigger: "blur" }
    ],
    storeTypes: [{ required: true, message: t("v210831.please-select"), trigger: "blur" }]
  };
  get orderId() {
    return this.$route.params.id;
  }
  handleConfirmCreateSupplier() {
    (this.$refs.addSupplierForm as any).validate(valid => {});
  }
  checkPrice(rule, value, callback) {
    if (!value) {
      callback();
    } else {
      priceRulesEl(value, callback);
    }
  }
  checkPeriod(rule, value, callback) {
    if (isPositiveInteger(value)) {
      callback();
    } else {
      callback(new Error(t("v210831.please-enter-a-integer")));
    }
  }
  created() {
    this.setPBPX(100);
    this.init();
  }
  async init() {
    await this.getPartsLists();
    await this.getServiceItemList();


    this.getDictionaryList("store_type").then(data => {
      this.storeTypeList = data;
    });
    if (!this.isAdd) {
      this.queryCardTemplateDetail({
        cardTemplateCode: this.orderId
      }).then(data => {
        data.data.partsList.forEach(item => {
          // 零配件回显
          if (
            !this.partsList.some(partsItem => {
              return partsItem.partsCode === item.partsCode;
            })
          ) {
            this.partsList.push(Object.assign({}, item));
          }
        });
        data.data.serviceList.forEach(item => {
          if (item.partsList && item.partsList.length > 0) {
            const partsIndex = this.groupIndex;
            item.groupServiceIndex = this.groupIndex++;
            item.partsList.forEach(item => {
              let partsData = Object.assign(item, {
                isGroupParts: true,
                groupServiceIndex: partsIndex
              });
              data.data.partsList.push(partsData);
            });
          }
          if (
            !this.serviceItemList.some(serviceItem => {
              return serviceItem.serviceCode === item.serviceCode;
            })
          ) {
            this.serviceItemList.push(Object.assign({}, item));
          }
        });
        // if (data.data.validPeriod === -1) {
        //   data.data.isPermanent = true;
        //   data.data.validPeriod = "180"; // 180为任意正整数,使前端验证通过
        // } else {
        //   data.data.isPermanent = false;
        // }
        this.orderFormData = data.data;
        if (data.data.validPeriod > 0 ){
          this.orderFormData.validPeriodType = 1
        }else if(data.data.validPeriod == -2){
          this.orderFormData.validPeriodType = 2
        }else {
          this.orderFormData.validPeriodType = 3
        }

        if(data.data.thirdMapList == null){
          data.data.thirdMapList = []
        }

        this.orderFormData.thirdMapList = data.data.thirdMapList

        this.iconFileList = data.data.icon ? [{url: data.data['icon']}] : [];

        this.storeList = data.data.storesList
          ? data.data.storesList.map(item => {
            item.label = item.storeName;
            item.value = item.storeCode;
            return item;
          })
          : []


        // if(this.orderFormData.useTimeType == 2){
        //   this.orderFormData.useTimeRange = [this.orderFormData.useTimeBegin, this.orderFormData.useTimeEnd]
        // }
        this.orderFormData.applicableStores = data.data.applicableStores
      });
    }
  }
  getPartsLists(name = "") {
    return this.getPartsList({
      pageNum: 1,
      pageSize: 20,
      searchKey: name,
      // storeTypeArray: this.orderFormData.storeTypes
    }).then(data => {
      this.partsList = data.data.list ? data.data.list : [];
    });
  }
  getServiceItemList(name = "") {
    return this.getServiceList({
      pageSize: 20,
      pageNum: 1,
      searchKey: name,
      // storeTypeArray: this.orderFormData.storeTypes
    }).then(data => {
      this.serviceItemList = data.data.list || [];
    });
  }
  handleStoreTypeChange() {
    this.getPartsLists();
    this.getServiceItemList();
    this.orderFormData.partsList = [];
    this.orderFormData.serviceList = [];
  }
  computedAmount(type, index) {
    if (type === "parts") {
      const currentParts = this.orderFormData.partsList[index];
      const amount = (currentParts.partsCount || 0) * currentParts.sellingPrice;
      currentParts.carryingAmount = amount.toFixed(2);
    } else {
      const currentServiceItem = this.orderFormData.serviceList[index];
      const amount = (currentServiceItem.serviceCount || 0) * currentServiceItem.serviceFee;
      currentServiceItem.partsList.forEach(item => {
        item.partsCount = currentServiceItem.serviceCount * item.servicePartsQty;
      });
      currentServiceItem.carryingAmount = amount.toFixed(2);
    }
    this.computedTotalAmount();
  }
  computedTotalAmount() {
    const partsTotal = this.orderFormData.partsList.reduce((preV, curV) => preV + Number(curV.carryingAmount), 0);
    const serviceTotal = this.orderFormData.serviceList.reduce((preV, curV) => preV + Number(curV.carryingAmount), 0);
    const amount = partsTotal + serviceTotal;
    if (Object.is(NaN, amount)) {
      this.$message.error(t("other.amount-quantity-number-tips") as string);
    }
    this.orderFormData.carryingAmount = amount.toFixed(2);
  }
  searchParts(val) {
    this.getPartsLists(val);
  }
  searchServiceItem(val) {
    this.getServiceItemList(val);
  }

  searchStore(query) {
    this.getAllStoreList({
      pageNum: 1,
      pageSize: 10,
      searchKey: query
    }).then(data => {
      this.storeList = data.data.list
        ? data.data.list.map(item => {
          item.label = item.storeName;
          item.value = item.storeCode;
          return item;
        })
        : []
    })
  }
  handleChooseParts(val, index) {
    this.getPartsDetail({
      partsCode: val
    }).then(data => {
      data = data.data || {};
      let partsData = Object.assign({}, this.definitionPartsData, {
        partsCode: val,
        partsUnit: data.partsUnit,
        sellingPrice: data.sellingPrice
      });
      this.orderFormData.partsList.splice(index, 1, partsData);
    });
  }
  handleCheckPermanent(isPermanent) {
    if (isPermanent) {
      this.orderFormData.validPeriod = "180"; // 随便取的值，为了通过大于0的验证
    } else {
      this.orderFormData.validPeriod = "";
    }
    (this.$refs["refCardTamplateInfo"] as any).validateField("validPeriod");
  }
  handleChooseServiceItem(val, index) {
    const haveSameService = this.orderFormData.serviceList.some((item, serviceRowIndex) => {
      return serviceRowIndex !== index && item.serviceCode === val;
    });
    if (haveSameService) {
      this.$message.error(t("marketing.not-choose-repeat-project") as string);
      this.orderFormData.serviceList[index].serviceCode = "";
      return;
    }
    this.getServiceDetail({
      serviceCode: val
    }).then(data => {
      data = data.data || {};
      const serviceItemData = Object.assign({}, this.definitionServiceData, {
        serviceCode: data.serviceCode,
        workingHours: data.workingHours,
        serviceFee: data.serviceFee,
        serviceType: data.serviceType,
        groupServiceIndex: this.groupIndex
      });
      this.orderFormData.serviceList.splice(index, 1, serviceItemData);
      if (data.serviceType === "2" && data.servicePartsList.length > 0) {
        this.orderFormData.serviceList[index].partsList = [];
        data.servicePartsList.forEach(item => {
          let partsData = Object.assign({}, this.definitionPartsData, {
            isGroupParts: true,
            partsName: item.partsName,
            partsUnit: item.partsUnit,
            sellingPrice: item.sellingPrice,
            servicePartsQty: item.servicePartsQty,
            partsCode: item.partsCode,
            partsCount: this.orderFormData.serviceList[index].serviceCount * item.servicePartsQty,
            serviceCode: val,
            groupServiceIndex: this.groupIndex
          });
          this.orderFormData.partsList.push(partsData);
          this.orderFormData.serviceList[index].partsList.push(partsData);
        });
        this.groupIndex++;
      }
      this.clearParts();
    });
  }
  clearParts() {
    // 清除未匹配的套餐零配件
    const selectedServiceGroupIndexArr = this.orderFormData.serviceList.map(item => {
      return item.groupServiceIndex;
    });
    for (let partsIndex = 0; partsIndex < this.orderFormData.partsList.length; partsIndex++) {
      let currentParts = this.orderFormData.partsList[partsIndex];
      if (currentParts.serviceCode && !selectedServiceGroupIndexArr.includes(currentParts.groupServiceIndex)) {
        this.orderFormData.partsList.splice(partsIndex, 1);
        partsIndex--;
      }
    }
  }
  checkAddData(): boolean {
    if (this.orderFormData.serviceList.length < 1) {
      this.$message.error(t("v210831.please-add-a-service"));
      return false;
    }
    for (let index = 0; index < this.orderFormData.serviceList.length; index++) {
      const element = this.orderFormData.serviceList[index];
      if (!element.serviceCount) {
        this.$message.error(t("v210831.please-enter-the-serive-number"));
        return false;
      }
    }
    for (let index = 0; index < this.orderFormData.partsList.length; index++) {
      const element = this.orderFormData.partsList[index];
      if (!element.partsCode) {
        this.$message.error(t("setting.select-spare-parts"));
        return false;
      }
      if (!element.partsCount && element.partsCount !== 0) {
        this.$message.error(t("v210831.please-enter-the-number-parts"));
        return false;
      }
      const checkPartsQty = partsQuantityRules(element.partsCount);
      if (!checkPartsQty.success) {
        this.$message.error(checkPartsQty.msg);
        return false;
      }
    }
    return true;
  }
  handleSaveCardTamplate() {
    (this.$refs["refCardTamplateInfo"] as any).validate(valid => {
      if (valid && this.checkAddData()) {
        const func = this.isAdd ? this.createCardTemplate : this.updateCardTemplate;
        const partsListCopy = this.orderFormData.partsList.filter(item => {
          return !item.isGroupParts;
        }); // 深拷贝
        const serviceListCopy = this.orderFormData.serviceList.slice(); // 深拷贝
        const orderFormDataCopy = Object.assign({}, this.orderFormData, {
          partsList: partsListCopy,
          serviceList: serviceListCopy
        });
        // 永久修改-1
        // orderFormDataCopy.validPeriod = orderFormDataCopy.isPermanent ? -1 : orderFormDataCopy.validPeriod;
        this.btnStartLoading();
        if(orderFormDataCopy.useTimeRange){
          orderFormDataCopy.useTimeBegin = orderFormDataCopy.useTimeRange[0];
          orderFormDataCopy.useTimeEnd = orderFormDataCopy.useTimeRange[1];
        }

        func(orderFormDataCopy)
          .then(data => {
            this.$message.success(t("base.save-success") as string);
            this.$router.push(
              `/management/card-template-view/${data.data ? data.data.cardTemplateCode : this.orderId}`
            );
          })
          .finally(data => {
            this.btnStopLoading();
          });
      }
    });
  }
  handleAddParts() {
    if (!this.orderFormData.storeTypes || this.orderFormData.storeTypes.length < 1) {
      return this.$message.error(t("v210831.please-select-the-store-type"));
    }
    this.orderFormData.partsList.push(Object.assign({}, this.definitionPartsData));
  }
  handleAddService() {
    if (!this.orderFormData.storeTypes || this.orderFormData.storeTypes.length < 1) {
      return this.$message.error(t("v210831.please-select-the-store-type"));
    }
    this.orderFormData.serviceList.push(Object.assign({}, this.definitionServiceData));
    this.searchServiceItem("");
  }

  handleAddGroup() {
    this.orderFormData.thirdMapList.push(Object.assign({}, this.definitionGroupData));
  }
  deleteItem(key, index) {

    if(key === "thirdMapList"){
      this.orderFormData[key].splice(index, 1);
      return
    }

    if (key === "serviceList" && this.orderFormData.serviceList[index].groupServiceIndex) {
      let service = this.orderFormData.serviceList[index];
      for (let partsIndex = 0; partsIndex < this.orderFormData.partsList.length; partsIndex++) {
        let parts = this.orderFormData.partsList[partsIndex];
        if (parts.groupServiceIndex === service.groupServiceIndex) {
          this.orderFormData.partsList.splice(partsIndex, 1);
          partsIndex--;
        }
      }
    }
    this.orderFormData[key].splice(index, 1);
    this.computedTotalAmount();
  }

  validPeriodTypeChange(val){
    if(val == 1){
      if(this.orderFormData.validPeriod < 0){
        this.orderFormData.validPeriod = 1
      }
      this.orderFormData.validTimeEnd = null
    }else if(val == 2){
      this.orderFormData.validPeriod = -2
    }else if(val == 3){
      this.orderFormData.validPeriod = -1
      this.orderFormData.validTimeEnd = null
    }
    this.$forceUpdate()
  }

  endTimeIsNextDayChange(e){
    if (e == 0){
      this.orderFormData.useTimeEnd = ''
    }
  }


  currThirdIndex: any = 0;
  handleAddCoupon(index){
    this.searchCouponStore();
    this.showCouponPopup = true;
    this.currThirdIndex = index
  }

  // 优惠券选择
  handleSelectedStoreCoupon() {
    this.mix_pageNum = 1;

    Vue.set(this.orderFormData.thirdMapList, this.currThirdIndex, { ...this.orderFormData.thirdMapList[this.currThirdIndex]
      , couponTemplateCode: this.selectedRowCoupon.activityCode, couponTemplateName: this.selectedRowCoupon.activityName});

    this.showCouponPopup = false;
  }

  searchForm = {
    cardTemplateName: "",
    enableStatus: 1, //（1：启用、0：禁用） 只显示启用的卡
    storeCode: ''
    // cardType: 1 //（1：套餐卡、2：储蓄卡） 0630这个版本先写死
  };

  handleSearchCoupon(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        this.searchForm[item.prop] = item.value;
      }
    });
    this.storeCouponListFn();
  }
  selectedRowCoupon: any = {};

  handleRowSelectCoupon(val){
    this.selectedRowCoupon = val;
  }

  handleRestCoupon(){
    this.selectedRowCoupon = {};
    this.searchForm.storeCode = ""
    // this.$refs.singleTable.setCurrentRow();
  }

  coupon_total: number = 0;
  coupon_pageNum: number = 1;
  coupon_pageSize: number = 10;

  couponSizeChange(e){
    this.coupon_pageSize = e;
    this.storeCouponListFn();

  }
  couponPageChange(e){
    this.coupon_pageNum = e;
    this.storeCouponListFn();
  }
  storeCouponList: any = [];

  // 获取优惠券列表
  storeCouponListFn(){
    this.mix_pageNum = 1;
    if(!this.searchForm.storeCode){
      this.$message.error("请先选择门店")
      return
    }
    this.getStoreCouponList({
      storeCode: this.searchForm.storeCode,
      pageNum: this.coupon_pageNum,
      pageSize: this.coupon_pageSize
    }).then(res => {
      this.storeCouponList = res.data.list;
      this.coupon_total = res.data.total;
    })
  }

  get couponSearch() {
    return [
      {
        label: "选择门店",
        type: "select-search",
        value: this.searchForm.storeCode,
        prop: "storeCode",
        placeholder: "请选择门店",
        selectList: this.storeList,
        searchFun: this.searchCouponStore
      }
    ];
  }

  // 获取门店列表
  searchCouponStore(val = ''){
    console.log(val)
    this.getStoreList({
      storeTypeList: ['3', '8', '9'],
      pageNum: 1,
      pageSize: 10,
      storeName: val
    }).then(res => {
      this.storeList = res.data.list ? res.data.list.map(item => {
          item.label = item.storeName;
          item.value = item.storeCode;
          return item;
        })
        : [];
      this.searchForm.storeCode = this.storeList[0].storeCode
      this.storeCouponListFn();
    })
  }
}
